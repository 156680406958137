.remove-focus-search-bar {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
}

.specific-form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    background-clip: padding-box;
    -webkit-appearance: none;
    appearance: none;
    border: none;
    outline: none;
}

.search-input-div {
    display: flex;
    padding: .375rem .75rem;
    background-color: #fff;
    background-clip: padding-box;
    -webkit-appearance: none;
    appearance: none;
}