.page-link {
    border-radius: 2px;
    border: none !important;
    color: #263F59 !important;
    background-color: #F5F7FA !important;
}

.page-item {
    margin: 0 auto;
}

.disabled > .page-link {
    opacity: 0.5;
}

.active > .page-link {
    color: #FFFFFF !important;
    background-color: #263F59 !important;
}

.pagination {
    justify-content: center !important;
    margin-top: 12px !important;
}

.first-pagination-mobile {
    margin-bottom: -4px !important;
    margin-top: 20px !important;
}

/* Desktops and laptops ----------- */
@media only screen 
and (min-width : 992px) {
    .pagination {
        padding-left: 25% !important;
        padding-right: 25% !important;
    }}

/* Large screens ----------- */
@media only screen 
and (min-width : 1824px) {
    .pagination {
        padding-left: 35% !important;
        padding-right: 35% !important;
    }
}