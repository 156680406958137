body {
  margin: 0;
  font-family: "PoppinsRegular";
  color: #263F59 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
   url("./style/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url("./style/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}
  
@font-face {
  font-family: "PoppinsItalic";
  src: local("PoppinsItalic"),
    url("./style/fonts/Poppins/Poppins-Italic.ttf") format("truetype");
}

