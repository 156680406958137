.navigation-link-mobile {
    transition: 0s !important;
    padding-left: 27px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.navigation-link {
    transition: 0s !important;
    padding-left: 27px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.navigation-link:first-child {
    padding-left: 0px !important;
}

.navigation-link:hover {
    border-radius: 8px;
    opacity: 0.4;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.navbar-toggler {
    border: none !important;
}

.navbar-toggler:focus {
    text-decoration: none !important;
    box-shadow: none !important;
}

.box-shadow {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}